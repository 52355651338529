<template>
  <q-card>
    <q-card-section class="row items-center q-py-xs bg-primary text-white">
      <div class="text-h5">Edit Record</div>
      <q-space />
      <q-btn icon="close" flat round dense v-close-popup />
    </q-card-section>
    <q-card-section>
      <div v-for="(value, key) in rowsRef" :key="key" class="q-my-md">
        <div
          v-if="
            Object.values(components_map.definitions).find((element) => {
              return element.name == key;
            })
          "
          style="max-width: 50%"
        >
          <component
            :is="components_map.definitions[key].input"
            :value="value"
            :label="components_map.definitions[key].label ?? ''"
            v-bind="components_map.definitions[key].settings ?? {}"
            @updateRowRef="rowsRef[key] = $event"
          />
        </div>
      </div>
    </q-card-section>

    <q-card-actions align="right">
      <q-btn
        class="text-weight-bold"
        label="Cancel"
        color="primary"
        v-close-popup
      />
      <q-btn
        flat
        class="text-weight-bold"
        label="Save"
        color="primary"
        @click="updateRow()"
        v-close-popup
      />
    </q-card-actions>
  </q-card>
</template>

<script async>
import { defineComponent, defineProps } from "vue";
import { computed, ref } from "@vue/reactivity";
import { useStore } from "vuex";

import store from "../../store";

const components_map = store?.getters[`datatable/componentMapping`];

export default defineComponent({
  setup() {
    return {
      components_map,
    };
  },
  components: components_map?.components,
});
</script>

<script setup>
const props = defineProps({
  module: String,
});

const $store = useStore();

const rowsRef = ref({});

const currentRow = computed(() => $store.getters[`${props.module}/currentRow`]);

function updateRow() {
  $store.dispatch(`${props.module}/updateRow`, rowsRef.value);
}

for (const [key, value] of Object.entries(currentRow.value)) {
  rowsRef.value[key] = value;
}
</script>

<style></style>
